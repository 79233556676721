import {
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { DynamicSystemPromptRepeater, FunctionRepeater } from 'components';
import {
  AnswersModality,
  ChatFunction,
  ChatModel,
  Direction,
  DynamicSystemPrompt,
  PromptVariable,
  Role,
  UpdateForm,
} from 'types';
import { apiUrl } from 'utils/constants';
import { fetchWithAuth } from 'utils';
import { AuthContext } from 'contexts';

export const UpdateChatForm = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isDynamicPromptDialogOpen, setIsDynamicPromptDialogOpen] = useState(false);
  const { id } = useParams();
  const query = new URLSearchParams(location.search);
  const duplicateChatId = query.get('duplicateChatId');
  const navigate = useNavigate();
  const { role, canEditDynamicPrompts } = useContext(AuthContext);
  const isAdmin = role === Role.ADMIN;

  const methods = useForm({
    defaultValues: {
      assistantEmail: '',
      answersModality: AnswersModality.TEXT,
      name: '',
      systemPrompt: '',
      dynamicSystemPrompts: [],
      whatsappPrompt: '',
      firstMessage: '',
      model: ChatModel.GPT_4O,
      canAcceptVoiceMessages: false,
      direction: Direction.RTL,
      textFieldLabel: '',
      openaiKey: '',
      twilioAccountSid: '',
      twilioAuthToken: '',
      twilioMessagingServiceSid: '',
      sendgridApiKey: '',
      airtableLogsBase: '',
      colors: '{}',
      temperature: 0.2,
      messagesInMemory: 40,
      showSources: false,
      functions: [],
      ttsPrompt: '',
      promptVariables: [], // Added default value for prompt variables
    },
  });

  const processChatFunctions = async (functions: ChatFunction[]) => {
    for (const f of functions) {
      if (f.id) {
        await fetchWithAuth(`${apiUrl}/chat-function/${f.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(f),
        });
      } else {
        f.chatId = id;
        const response = await fetchWithAuth(`${apiUrl}/chat-function`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(f),
        });
        if (response.status !== 200) {
          throw new Error('Something went wrong!');
        }
      }
    }
  };

  const formPropsHandler = (data: UpdateForm) => {
    data.functions?.forEach((f) => {
      f.parameters = f.parameters ? JSON.parse(f.parameters as string) : {};
    });
    data.dynamicSystemPrompts = data.dynamicSystemPrompts?.map((d) => ({
      id: Number(d.id ?? -1),
      name: d.name,
      condition: d.condition,
      prompt: d.prompt,
      zIndex: Number(d.zIndex),
    }));
    data.promptVariables = data.promptVariables?.map((v) => ({
      id: Number(v.id ?? -1),
      name: v.name,
      value: v.value,
      injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
      injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
      injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
      injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
    }));
    data.temperature = Number(data.temperature);
    data.messagesInMemory = Number(data.messagesInMemory);
    data.colors = JSON.parse(data.colors);
    data.showSources =
      typeof data.showSources === 'string' ? JSON.parse(data.showSources) : data.showSources;
  };

  const onSubmit: SubmitHandler<UpdateForm> = async (data) => {
    formPropsHandler(data);
    try {
      const functions = data.functions;
      delete data.functions;
      if (id) {
        data.dynamicSystemPrompts = {
          deleteMany: { createdAt: { lt: new Date() } },
          upsert: data.dynamicSystemPrompts?.map((d: DynamicSystemPrompt) => ({
            where: { id: d.id },
            update: {
              condition: d.condition,
              prompt: d.prompt,
              name: d.name,
              zIndex: d.zIndex,
            },
            create: {
              id: undefined,
              name: d.name,
              condition: d.condition,
              prompt: d.prompt,
              zIndex: d.zIndex,
            },
          })),
        } as never;
        data.promptVariables = {
          deleteMany: { createdAt: { lt: new Date() } },
          upsert: data.promptVariables?.map((v: PromptVariable) => ({
            where: { id: v.id },
            update: {
              name: v.name,
              value: v.value,
              injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
              injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
              injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
              injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
            },
            create: {
              id: undefined,
              name: v.name,
              value: v.value,
              injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
              injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
              injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
              injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
            },
          })),
        } as never;
        const response = await fetchWithAuth(`${apiUrl}/chat-metadata/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });

        if (response.status !== 200) {
          throw new Error('Something went wrong!');
        }
      } else {
        data.dynamicSystemPrompts = {
          create: data.dynamicSystemPrompts?.map((d: DynamicSystemPrompt) => ({
            id: undefined,
            condition: d.condition,
            prompt: d.prompt,
            zIndex: d.zIndex,
          })),
        } as never;
        data.promptVariables = {
          create: data.promptVariables?.map((v: PromptVariable) => ({
            id: undefined,
            name: v.name,
            value: v.value,
            injectToStartOfSystemPrompt: v.injectToStartOfSystemPrompt,
            injectToEndOfSystemPrompt: v.injectToEndOfSystemPrompt,
            injectToStartOfDynamicPrompt: v.injectToStartOfDynamicPrompt,
            injectToEndOfDynamicPrompt: v.injectToEndOfDynamicPrompt,
          })),
        } as never;
        const response = await fetchWithAuth(`${apiUrl}/chat-metadata`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        const newChat = await response.json();
        navigate(`/dashboard/edit/${newChat.id}`);
      }

      if (functions) {
        await processChatFunctions(functions);
      }
      fetchChatMetadata();
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error Submitting data:', error);
    }
  };

  const fetchChatMetadata = async () => {
    if (!id && !duplicateChatId) return; // Skip fetching if creating a new chatbot
    const chatId = duplicateChatId || id;
    try {
      const response = await fetchWithAuth(`${apiUrl}/chat-metadata/${chatId}`);
      const jsonData = await response.json();
      methods.setValue('name', `${duplicateChatId ? 'Copy of ' : ''}${jsonData.name}`);
      methods.setValue('textFieldLabel', jsonData.textFieldLabel);
      methods.setValue('systemPrompt', jsonData.systemPrompt);
      methods.setValue('dynamicSystemPrompts', jsonData.dynamicSystemPrompts);
      methods.setValue('whatsappPrompt', jsonData.whatsappPrompt);
      methods.setValue('firstMessage', jsonData.firstMessage);
      methods.setValue('model', jsonData.model);
      methods.setValue('canAcceptVoiceMessages', jsonData.canAcceptVoiceMessages);
      methods.setValue('answersModality', jsonData.answersModality);
      methods.setValue('direction', jsonData.direction);
      methods.setValue('colors', JSON.stringify(jsonData.colors));
      methods.setValue('temperature', jsonData.temperature);
      methods.setValue('showSources', jsonData.showSources);
      methods.setValue('openaiKey', jsonData.openaiKey);
      methods.setValue('twilioAccountSid', jsonData.twilioAccountSid);
      methods.setValue('twilioAuthToken', jsonData.twilioAuthToken);
      methods.setValue('twilioMessagingServiceSid', jsonData.twilioMessagingServiceSid);
      methods.setValue('assistantEmail', jsonData.assistantEmail);
      methods.setValue('sendgridApiKey', jsonData.sendgridApiKey);
      methods.setValue('airtableLogsBase', jsonData.airtableLogsBase);
      methods.setValue('messagesInMemory', jsonData.messagesInMemory);
      methods.setValue('ttsPrompt', jsonData.ttsPrompt);
      methods.setValue('promptVariables', jsonData.promptVariables);

      jsonData.functions?.forEach((f: ChatFunction) => {
        f.parameters = JSON.stringify(f.parameters);
      });
      methods.setValue('functions', jsonData.functions);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSnackbarClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  useEffect(() => {
    fetchChatMetadata();
  }, [id]);

  return (
    <FormProvider {...methods}>
      <Box component="form" autoComplete="off" onSubmit={methods.handleSubmit(onSubmit)}>
        <Card
          elevation={24}
          className="dashboard"
          sx={{
            padding: 2,
            borderRadius: 2,
          }}
        >
          <Grid container spacing={2} sx={{ marginBottom: '1rem' }}>
            {isAdmin && (
              <>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="name"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Name"
                        type="text"
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={!!error}
                        helperText={error ? error.message : null}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="model-label">Model</InputLabel>
                    <Controller
                      name="model"
                      control={methods.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Select
                          labelId="model-label"
                          id="model"
                          name="model"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          label="Model"
                          fullWidth
                        >
                          {Object.values(ChatModel).map((i) => (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="canAcceptVoiceMessages"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            checked={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            color="primary"
                          />
                        }
                        label="Can Accept Voice Messages"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Answers Modality</InputLabel>
                    <Controller
                      name="answersModality"
                      control={methods.control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          name="answersModality"
                          label="Answers Modality"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          fullWidth
                        >
                          <MenuItem value={AnswersModality.TEXT}>Text</MenuItem>
                          <MenuItem value={AnswersModality.VOICE}>Voice</MenuItem>
                          <MenuItem value={AnswersModality.TEXT_AND_VOICE}>Text and Voice</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="ttsPrompt"
                      control={methods.control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <TextField
                          name="ttsPrompt"
                          label="TTS Prompt"
                          variant="outlined"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          fullWidth
                          multiline
                          rows={4}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Controller
                name="systemPrompt"
                control={methods.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    multiline
                    label="System Prompt"
                    name="systemPrompt"
                    variant="outlined"
                    rows={20}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            {canEditDynamicPrompts && (
              <Grid item xs={12} display="flex" marginTop={-5}>
                <Button
                  variant="contained"
                  color="success"
                  size="large"
                  sx={{ zIndex: 1, flexGrow: 1, marginX: 2 }}
                  onClick={() => setIsDynamicPromptDialogOpen(true)}
                >
                  ⑆ Dynamic Prompts
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="whatsappPrompt"
                control={methods.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    multiline
                    label="Whatsapp Prompt"
                    name="whatsappPrompt"
                    variant="outlined"
                    rows={6}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="firstMessage"
                control={methods.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    multiline
                    label="First Message"
                    name="firstMessage"
                    variant="outlined"
                    rows={4}
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="textFieldLabel"
                control={methods.control}
                render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                  <TextField
                    label="Text Field Label"
                    name="textFieldLabel"
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            </Grid>
            {isAdmin && (
              <>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="direction-label">Direction</InputLabel>
                    <Controller
                      name="direction"
                      control={methods.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Select
                          labelId="direction-label"
                          id="direction"
                          name="direction"
                          label="direction"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          fullWidth
                        >
                          {Object.values(Direction).map((i) => (
                            <MenuItem key={i} value={i}>
                              {i}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="colors"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Colors"
                        name="colors"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="temperature"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Temperature"
                        name="temperature"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="show-sources-label">Show Sources</InputLabel>
                    <Controller
                      name="showSources"
                      control={methods.control}
                      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                        <Select
                          labelId="show-sources-label"
                          id="showSources"
                          name="showSources"
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          error={!!error}
                          label="showSources"
                          fullWidth
                        >
                          <MenuItem value="true">True</MenuItem>
                          <MenuItem value="false">False</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Controller
                    name="messagesInMemory"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Messages In Memory"
                        name="messagesInMemory"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="openaiKey"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="OpenAI Key"
                        name="openaiKey"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="twilioAccountSid"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Twilio Account Sid"
                        name="twilioAccountSid"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="twilioAuthToken"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Twilio Auth Token"
                        name="twilioAuthToken"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="twilioMessagingServiceSid"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Twilio Messaging Service Sid"
                        name="twilioMessagingServiceSid"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="sendgridApiKey"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Sendgrid Api Key"
                        name="sendgridApiKey"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Controller
                    name="assistantEmail"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Assistant Email"
                        name="assistantEmail"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="airtableLogsBase"
                    control={methods.control}
                    render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
                      <TextField
                        label="Airtable Logs Base"
                        name="airtableLogsBase"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{
                      marginBottom: '.5rem',
                    }}
                  >
                    Functions
                  </Typography>
                  <FunctionRepeater />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container sx={{ justifyContent: 'center' }}>
            <Grid item>
              <Button type="submit" variant="contained" color="secondary" size="large">
                Save
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        message="Chat Updated!"
      />
      <Dialog
        open={isDynamicPromptDialogOpen}
        onClose={() => setIsDynamicPromptDialogOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DynamicSystemPromptRepeater />
      </Dialog>
    </FormProvider>
  );
};
