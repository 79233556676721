import { Button } from 'components/shadcn/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/shadcn/dialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/select';
import { Input } from 'components/shadcn/input';
import { Label } from 'components/shadcn/label';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shadcn/table';
import { Pencil, Plus } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Role, User } from 'types';
import { apiUrl, fetchWithAuth } from 'utils';
import { Checkbox } from 'components/shadcn/checkbox';

export const UserList = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [chats, setChats] = useState<User['chats']>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [dialogEmail, setDialogEmail] = useState('');
  const [dialogPassword, setDialogPassword] = useState('');
  const [dialogRole, setDialogRole] = useState<Role>();
  const [dialogChats, setDialogChats] = useState<string[]>([]);
  const [dialogCanEditDynamicPrompts, setDialogCanEditDynamicPrompts] = useState(false);

  useEffect(() => {
    fetchUsers().then((data) => setUsers(data));
    fetchChats().then((data) => setChats(data));
  }, []);

  useEffect(() => {
    setDialogEmail(selectedUser?.email || '');
    setDialogRole(selectedUser?.role || undefined);
    setDialogChats(selectedUser?.chats?.map((chat) => chat.id) || []);
    setDialogPassword('');
    setDialogCanEditDynamicPrompts(selectedUser?.canEditDynamicPrompts || false);
  }, [selectedUser]);
  const isEditing = !!selectedUser && !isCreating;

  async function fetchChats() {
    try {
      const response = await fetchWithAuth(`${apiUrl}/chat-metadata`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching chats:', error);
    }
  }

  async function fetchUsers() {
    try {
      const response = await fetchWithAuth(`${apiUrl}/users`);
      return await response.json();
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  }

  async function submitDialog() {
    const url = isCreating ? `${apiUrl}/users` : `${apiUrl}/users/${selectedUser?.id}`;
    const method = isCreating ? 'POST' : 'PUT';
    const body = JSON.stringify({
      email: dialogEmail,
      password: dialogPassword || undefined,
      role: dialogRole,
      chats: dialogChats,
      canEditDynamicPrompts: dialogCanEditDynamicPrompts,
    });
    console.info(body);

    try {
      setIsLoading(true);
      await fetchWithAuth(url, { method, body });
      setUsers(await fetchUsers());
      setIsCreating(false);
      setSelectedUser(undefined);
    } catch (error) {
      console.error('Error submitting dialog:', error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="mx-auto py-6 px-4 lg:px-6 lg:max-w-screen-lg flex-grow self-start box-border w-full">
      <div className="flex flex-row gap-2 justify-between items-center mb-10">
        <h1 className="text-4xl font-black text-secondary">USERS</h1>
        <Button variant="secondary" color="success" onClick={() => setIsCreating(true)}>
          <Plus className="mr-2 h-4 w-4" /> Create User
        </Button>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Email</TableHead>
              <TableHead>Role</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {users?.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell>
                  <Button variant="link" size="icon" onClick={() => setSelectedUser(user)}>
                    <Pencil className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <Dialog
        open={isCreating || isEditing}
        onOpenChange={() => {
          setSelectedUser(undefined);
          setIsCreating(false);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{isCreating ? 'Create User' : 'Edit User'}</DialogTitle>
          </DialogHeader>
          <div
            className="flex flex-col gap-4 max-h-[500px] overflow-y-auto p-4"
            style={{ scrollbarColor: '#E0E0E3 transparent;' }}
          >
            <div>
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={dialogEmail}
                required
                onChange={(e) => setDialogEmail(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                required={isCreating}
                value={dialogPassword}
                onChange={(e) => setDialogPassword(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="role">Role</Label>
              <Select
                onValueChange={(value) => setDialogRole(value as Role)}
                value={dialogRole}
                required
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select Role">{dialogRole || 'Select Role'}</SelectValue>
                </SelectTrigger>
                <SelectContent position="popper">
                  {Object.values(Role).map((role) => (
                    <SelectItem key={role} value={role}>
                      {role}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <Label>Permissions</Label>
              <div className="flex flex-row gap-2 items-center">
                <Checkbox
                  checked={dialogCanEditDynamicPrompts}
                  onCheckedChange={(checked) => setDialogCanEditDynamicPrompts(!!checked)}
                  id="canEditDynamicPrompts"
                />
                <label htmlFor="canEditDynamicPrompts" className="cursor-pointer">
                  Can Edit Dynamic Prompts
                </label>
              </div>
            </div>
            <div>
              <Label>Chats</Label>
              {chats?.map((chat) => (
                <div className="flex flex-row gap-2 items-center" key={chat.id}>
                  <Checkbox
                    id={chat.id}
                    checked={dialogChats.includes(chat.id)}
                    onCheckedChange={(checked) =>
                      setDialogChats((prev) =>
                        checked ? [...prev, chat.id] : prev.filter((id) => id !== chat.id),
                      )
                    }
                  />
                  <label htmlFor={chat.id} className="cursor-pointer">
                    {chat.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <DialogFooter>
            <Button variant="ghost" onClick={() => setIsCreating(false)} disabled={isLoading}>
              Cancel
            </Button>
            <Button onClick={submitDialog} disabled={isLoading} variant="secondary">
              {isCreating ? 'Create' : 'Save'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
